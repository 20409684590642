@use "src/styles/variables";

.tierBadge {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  height: 18px;
  padding: 0 variables.px2rem(8.5px);
  font-size: variables.px2rem(11px);
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  border-radius: 20px;
  background-color: variables.$tier-0;
  min-width: 50px;

  &.tier_1 {
    background-color: variables.$tier-1;
  }

  &.tier_2 {
    background-color: variables.$tier-2;
  }

  &.tier_3,
  &.tier_4 {
    background-color: variables.$tier-3;
  }
}
