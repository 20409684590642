@use "src/styles/variables";

.kycSummary {
  h2 {
    font-weight: 600;
    font-size: variables.px2rem(20px);
    line-height: variables.px2rem(24px);
  }

  .alert {
    margin-bottom: variables.px2rem(30px);

    a {
      color: inherit;
      text-decoration: underline;
    }

    :global {
      .ant-alert-content {
        min-height: variables.px2rem(24px);
        display: flex;
        align-items: center;
      }
    }
  }

  .wrapper {
    > div {
      border: none;
    }
  }

  .confirmCheckbox {
    align-items: flex-start;

    svg {
      margin-top: variables.px2rem(2px);
    }

    a {
      display: inline-block;
    }
  }
}
