@use "src/styles/variables";

.addressDetails {
  h2 {
    font-size: variables.px2rem(20px);
    font-weight: 600;
    line-height: variables.px2rem(24px);
    margin-bottom: 6px;
  }

  p {
    margin-bottom: 20px;
  }

  .formSpace {
    margin-top: 30px;
    width: 100%;
  }
}
