@use "src/styles/variables";

.proofOfIdentityInit {
  h2 {
    font-size: variables.px2rem(20px);
    line-height: variables.px2rem(24px);
    font-weight: 600;
  }

  .essential {
    display: flex;
    gap: variables.px2rem(15px);
    align-items: center;
    margin-bottom: 20px;

    h3 {
      font-size: variables.px2rem(16px);
      line-height: variables.px2rem(22px);
      margin-bottom: 0;
      font-weight: 600;
    }

    p {
      color: variables.$text-color-secondary;
      margin-bottom: 0;
    }
  }

  .documentAlignments {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: variables.px2rem(45px);

    .documentExample {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .documentDescription {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: variables.px2rem(5px);
      font-size: variables.px2rem(12px);
      line-height: variables.px2rem(14px);

      svg {
        width: 18px;
        height: 18px;

        &.good {
          fill: variables.$alert-success;
        }
      }
    }
  }

  .loading {
    svg {
      stroke: variables.$color-primary;
    }
  }
}
