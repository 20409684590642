@use "src/styles/variables";

$svgSize: 20px;

.selectionIndicator {
  $btn: &;
  cursor: pointer;

  :global(.ant-space-item):first-of-type {
    height: $svgSize;
  }

  svg {
    width: $svgSize;
    height: $svgSize;
  }

  &:not(.checked) {
    &:hover {
      svg {
        stroke: variables.$text-color-secondary;
      }
    }
  }
}
