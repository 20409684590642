@use "src/styles/variables";
@use "src/styles/breakpoints";

.welcomePageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  gap: variables.px2rem(10px);
  text-align: center;

  h2 {
    font-size: variables.px2rem(36px);
  }

  p {
    opacity: 0.5;
    font-size: variables.px2rem(14px);
    line-height: variables.px2rem(20px);

    @include breakpoints.lg {
      font-size: variables.px2rem(16px);
      line-height: variables.px2rem(22px);
    }
  }
}
