@use "src/styles/colors";

.statusBtn {
    svg {
        transform: rotate(-90deg);
    }

    &.default {
        svg {
            stroke: white;
        }
    }

    &.disabled {
        color: colors.$grey-300;
        
        svg {
            stroke: colors.$grey-300;
        }
    }
}
