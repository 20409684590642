@use "src/styles/variables";
@use "src/styles/colors";

.fileUploadWrapper {
  & > div {
    border: none !important;
  }

  .fileUpload {
    display: flex;
    flex-direction: column;
    gap: 10px;

    input {
      display: none;
    }

    .dropzone {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1.5px dashed variables.$button-stroke;
      border-radius: 20px;
      background-color: colors.$grey-50;
      color: variables.$text-color-secondary;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        background-color: colors.$white;
        border-color: colors.$green-600;
        color: variables.$text-color-main;

        svg {
          stroke: variables.$text-color-main;
        }
      }

      svg {
        width: 60px;
        height: 60px;
        margin-bottom: 5px;
        transition: all .3s;
      }

      b {
        text-decoration: underline;
      }

      &.active {
        border-color: variables.$button-success;

        svg {
          stroke: variables.$button-success;
        }
      }

      &.error {
        border-color: variables.$alert-danger;
      }
    }

    .fileItem {
      padding: variables.px2rem(10px);
      border-radius: variables.px2rem(10px);
      background-color: colors.$grey-100;
      font-weight: 600;

      &.error {
        background-color: rgba(variables.$alert-danger, 0.15);
      }

      .iconHolder {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .fileIcon {
          width: 30px;
          height: 30px;
        }

        .trashIcon {
          width: 18px;
          height: 18px;
        }

        &.trashHolder {
          width: 30px;
          height: 30px;

          &:hover {
            .trashIcon {
              stroke: variables.$alert-danger;
            }
          }
        }
      }
    }

    .fileError {
      color: variables.$alert-danger;
      line-height: variables.px2rem(18px);
      margin-top: variables.px2rem(5px);
      margin-bottom: 0;
    }
  }
}
