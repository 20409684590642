@use "src/styles/variables";
@use "src/styles/breakpoints";

.appHeader {
  width: 100%;
  align-items: center;
  padding: variables.px2rem(25px) variables.px2rem(20px);

  @include breakpoints.lg {
    padding: variables.px2rem(50px) 0;
  }

  .mainCol {
    margin-top: variables.px2rem(25px);

    @include breakpoints.lg {
      margin-top: 0;
    }

    h2 {
      font-size: variables.px2rem(22px);
      line-height: variables.px2rem(26px);
      font-weight: 500;
      margin: 1em 0 0 0;

      @include breakpoints.lg {
        font-size: variables.px2rem(36px);
        line-height: variables.px2rem(43px);
      }
    }
  }

  .rightSide {
    display: flex;
    justify-content: flex-end;
  }
}
