@use "src/styles/variables";

.stepsContainer {
  padding-top: variables.px2rem(40px);
  display: flex;
  align-items: center;
  gap: variables.px2rem(5px);

  &.center {
    justify-content: center;
  }

  .step {
    position: relative;

    &Icon {
      fill: #fff;
      width: 18px;
      height: 18px;
      opacity: 0.5;
      $icon: &;

      :global(.tick) {
        stroke: variables.$color-primary;
      }
    }

    p {
      color: #fff;
      position: absolute;
      top: variables.px2rem(-25px);
      left: 50%;
      margin-bottom: 0;
      width: max-content;
      transform: translateX(-50%);
      opacity: 0.5;

      @media (max-width: 768px) {
        font-size: variables.px2rem(10px);
      }
    }

    &.active {
      .stepIcon {
        opacity: 1;
      }

      p {
        opacity: 1;
      }
    }
  }

  .progress {
    margin-right: 0;
    :global(.ant-progress-outer) {
      width: 100% !important;
    }
  }
}
