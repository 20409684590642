@use "src/styles/variables";
@use "src/styles/breakpoints";

.kycPage {
  @include breakpoints.lg {
    margin: 0 variables.px2rem(85px);
  }

  .appHeader {
    padding-bottom: 1rem;
    max-width: 1100px;
    margin: 0 auto;

    .header {
      color: #fff;
      max-width: min(calc(100% - 80px), 400px);
      text-align: center;
      margin: 0 auto;
    }
  }

  .body {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: variables.px2rem(25px);
  }

  .loading {
    height: "100vh";

    svg {
      stroke: variables.$color-primary;
    }
  }
}
