@use "src/styles/variables";

.inputWrapper {
  width: 100%;

  .label {
    font-size: variables.px2rem(16px);
    line-height: variables.px2rem(20px);
    font-weight: 600;
    margin-bottom: variables.px2rem(6px);
    display: inline-flex;
    gap: 5px;

    &Optional {
      color: variables.$text-color-secondary;
    }

    .tooltipIcon {
      height: 18px;

      svg {
        height: 18px;
      }
    }
  }

  .inputContainer {
    border: 1px solid variables.$button-stroke;
    border-radius: 10px;
    width: 100%;

    &:hover,
    &:focus,
    &:focus-within {
      border-color: variables.$text-color-secondary;
    }

    &.invalid {
      border-color: variables.$alert-danger;

      :global {
        .ant-row {
          border-color: variables.$alert-danger;
        }
      }
    }
  }

  .error {
    margin-top: variables.px2rem(3px);
    margin-bottom: 0;
    line-height: variables.px2rem(18px);
    color: variables.$alert-danger;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}
