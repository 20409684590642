@use "src/styles/variables";
@use "src/styles/colors";

.proofOfIdentityCreated {
  h2 {
    font-size: variables.px2rem(20px);
    line-height: variables.px2rem(22px);
    font-weight: 600;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 45px;
  }

  .documentIconContainer {
    display: flex;
    justify-content: center;
  }

  .resetVeriffButton {
    display: flex;
    justify-content: center;
    margin: 0.5em auto;
    padding: 0;
    background-color: transparent;
    color: colors.$grey-900;
    border: transparent;
    text-decoration: underline;
    font-size: variables.px2rem(14px) !important;
  }

  .backBtn {
    margin-top: 0;

    &:hover {
      color: colors.$grey-900;
    }
  }

  .margin0 {
    margin: 0 auto;
  }

  .backToDashboard {
    border: 1px solid #2ec973 !important;
    text-decoration: none !important;
    border-radius: 10px !important;
    height: 52px !important;
    margin-top: 10px;
  }

  .actions {
    width: 100%;

    > div {
      width: 100%;
    }
  }
}
