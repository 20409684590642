@use "./variables";
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700|Roboto:400,700);

* {
  box-sizing: border-box;
}

html,
body {
  height: unset;
}

html {
  font-size: variables.$font-size-base;
  background-color: #83109c;
}

body {
  margin: 0;
  font-family: variables.$font-family;
  font-size: variables.$font-size-base;
  line-height: variables.px2rem(20px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: variables.$background-gradient no-repeat fixed;
  min-height: 100vh;
  color: variables.$text-color-main;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  a {
    text-decoration: none;
  }
}
