@use "src/styles/variables";

$border: 1px solid variables.$button-stroke;
$margin: variables.px2rem(18px);

.dividerBox {
  position: relative;

  .divider {
    border-bottom: $border;
    margin: $margin 0;
    height: 100%;

    &.vertical {
      border-bottom: none;
      border-right: $border;
      margin: 0 $margin;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
