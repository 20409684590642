@use "src/styles/variables";

.backToCoreButton {
  width: variables.px2rem(180px);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  font-size: variables.px2rem(14px);
  line-height: variables.px2rem(18px);

  span {
    display: flex;
  }
  svg {
    margin-left: variables.px2rem(8px);
  }

  &:hover {
    opacity: 1 !important;
    background-color: #fff !important;
    border-color: #fff !important;
    color: #323232 !important;

    svg {
      filter: invert(17%) sepia(0%) saturate(1840%) hue-rotate(259deg) brightness(94%) contrast(89%);
      margin-right: variables.px2rem(-10px);
      margin-left: variables.px2rem(18px);
    }
  }
}
