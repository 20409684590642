$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

@mixin xxs {
  @media (max-width: calc(#{$xs} - 1px)) {
    @content;
  }
}

@mixin xs {
  @media (min-width: #{$xs}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$sm}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xl}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$xxl}) {
    @content;
  }
}
