@use "src/styles/variables";
@use "src/styles/breakpoints";

@mixin alert-color($color) {
  background-color: rgba($color, 0.03);
  border: 1px solid $color;
}

.alert {
  border-radius: variables.px2rem(12px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);

  :global(.ant-alert-icon) {
    width: variables.px2rem(24px);
    height: variables.px2rem(24px);
  }

  .successIcon {
    fill: variables.$alert-success;
    stroke: variables.$alert-success;
  }

  :global(.ant-alert-message) {
    font-weight: 600;
    font-size: variables.px2rem(16px);
    line-height: variables.px2rem(22px);
    margin-bottom: variables.px2rem(2px);
  }

  :global(.ant-alert-description) {
    font-size: variables.px2rem(14px);
    line-height: variables.px2rem(18px);

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &.success {
    @include alert-color(variables.$alert-success);
  }

  &.warning {
    @include alert-color(variables.$alert-warning);
  }

  &.greyOut {
    background-color: variables.$alert-grey-out-background;
    border: 1px solid variables.$alert-grey-out;
    color: variables.$text-color-main;

    svg {
      fill: variables.$alert-grey-out !important;
      color: variables.$alert-grey-out !important;
      stroke: variables.$alert-grey-out !important;
    }
  }

  &.error {
    @include alert-color(variables.$alert-danger);
  }

  .rejectionCollapsable {
    background-color: transparent;
    border: none;

    &_panel {
      border-radius: 0 !important;

      :global(.ant-collapse-header) {
        padding: variables.px2rem(12px) variables.px2rem(3.5px);
        font-weight: 600;
      }

      :global(.ant-collapse-content) {
        background-color: transparent;
      }
    }
  }

  &.edd {
    .statusContainer {
      display: flex;
      gap: variables.px2rem(4px);
    }

    .actionBtn {
      width: 100%;
    }

    @media screen and (max-width: 500px) {
      flex-wrap: wrap;

      :global {
        .ant-alert-action {
          padding-left: 25px;
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }

  .customAlertBody {
    display: flex;
    justify-content: space-between;
  }

  .customAlertAction {
    @include breakpoints.sm {
      margin-top: variables.px2rem(-24px);
      width: 100vw;
      max-width: variables.px2rem(160px);
    }

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inlineAlertAction {
    @include breakpoints.sm {
      margin-top: variables.px2rem(-24px);
      padding-left: variables.px2rem(20px);
      width: 100vw;
      max-width: variables.px2rem(220px);
    }

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: variables.px2rem(5px);
  }

  .eddRejectionAlertCollapse {
    padding-right: variables.px2rem(36px);
  }

  .alertActionDesktop {
    display: none;

    @include breakpoints.sm {
      display: block;
    }
  }

  .alertActionMobile {
    display: block;
    margin-top: variables.px2rem(10px);

    @include breakpoints.sm {
      display: none;
    }
  }

  .eddAction {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .eddAlertDescriptionText {
    width: 90%;
  }

  &.ukRestrictedPromotion {
    :global(.ant-alert-action) {
      margin: auto 0;
    }

    a {
      font-weight: 600;
    }

    .timerBtn {
      color: variables.$text-color-main !important;
    }

    .ukRestrictedAlertBody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: variables.px2rem(8px);

      & > div {
        width: 100%;

        button {
          width: 100%;
        }
      }

      @include breakpoints.sm {
        flex-direction: row;
      }
    }
  }
}
