.indicatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    stroke: #fff;
    stroke-width: 1;
  }
}
