@use "src/styles/variables";
@use "src/styles/colors";

.statusBadge {
  display: flex;
  align-items: center;
  gap: variables.px2rem(3px);

  svg {
    width: 16px;
    height: 16px;
  }

  &.pending {
    span {
      color: colors.$orange-900;
    }
  }

  &.comingSoon {
    svg {
      stroke: colors.$grey-600;
    }
  }

  &.rejected {
    span {
      color: colors.$red-600;
    }
  }

  &.aboutToExpired {
    svg {
      fill: colors.$orange-900;
    }
  }

  &.draft,
  &.comingSoon {
    color: colors.$grey-600;
  }

  &.pending,
  &.submitted {
    p {
      margin-bottom: 0;
    }
  }

  &.accepted {
    svg {
      fill: variables.$alert-success;
    }
  }

  &.outdated {
    svg {
      fill: variables.$alert-danger;
    }
  }
}

.pendingUpToText {
  font-size: variables.px2rem(12px);
  color: colors.$grey-600;
}