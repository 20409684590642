@use "src/styles/variables";
@use "src/styles/colors";

.answerOption {
  padding: 15px;
  border: 1px solid variables.$button-stroke;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  transition: background-color .3s;

  & > :global(.ant-col) {
    display: flex;
    align-items: center;
  }

  .rightSide {
    justify-content: flex-end;
  }

  .answerOptionText {
    transition: all .3s;
  }

  &:hover {
    background-color: colors.$grey-100;
    border-color: colors.$grey-200;

    .answerOptionText {
      transform: translateX(10px);
    }
  }  

  &:not(.selected) {
    &:focus-within,
    &:focus-visible,
    &:focus,
    &:active {
      border-color: variables.$text-color-secondary;
      outline: none;
    }
  }

  &.selected {
    border-color: variables.$button-success;
    background-color: rgba(variables.$button-success, 0.03);
  }

  &.disabled {
    color: variables.$text-color-secondary;
    pointer-events: none;
    cursor: not-allowed;
  }
}
