@use "src/styles/variables";

.rolledList {
  &__header {
    display: flex;
    gap: variables.px2rem(12px);
    align-items: center;
    margin-bottom: variables.px2rem(10px);
  }

  ul {
    padding-inline-start: variables.px2rem(23px);
    margin-bottom: 0.5rem;

    li {
      padding-left: variables.px2rem(14px);
      margin-bottom: variables.px2rem(5px);
    }
  }

  a {
    margin-left: variables.px2rem(36px);

    .expandBtn {
      display: inline-flex;

      &:hover {
        svg {
          stroke: variables.$button-success;
        }
      }

      svg {
        &.opened {
          transform: rotate(180deg);
        }
      }
    }
  }
}
