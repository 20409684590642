@use "src/styles/variables";

$paddingSize: variables.px2rem(15px);

.select {
  width: 100%;
  height: 52px;

  :global(.ant-select-selector) {
    font-size: variables.px2rem(16px);
    line-height: variables.px2rem(22px);
    padding: $paddingSize !important;
    height: 100% !important;
    display: flex;
    align-items: center;

    :global(.ant-select-selection-search) {
      inset-inline-start: $paddingSize;
      inset-inline-end: $paddingSize;

      input {
        height: 50px !important;
      }
    }
  }
}

.menu {
  border-radius: 10px;
  border: 1px solid variables.$button-stroke;
  box-shadow: 0 5px 10px rgba(#000, 0.05);
}
