@use "src/styles/variables";
@use "src/styles/colors";

.customModal {
  :global {
    .ant-modal-content {
      padding: variables.px2rem(40px) variables.px2rem(80px);
    }
  }
  .modalTitle {
    font-size: variables.px2rem(20px);
    font-weight: bold;
  }
  .modalContent {
    font-size: variables.px2rem(16px);
  }

  .modalOKButton {
    display: block;
    width: 100%;
    margin: 3em 0 0 0 !important;
    height: variables.px2rem(52px);
    font-size: variables.px2rem(16px);
  }

  .modalCancelButton {
    display: block;
    width: 100%;
    border: transparent;
    background-color: transparent;
    color: colors.$grey-900;
    font-size: variables.px2rem(16px);

    &:hover {
      background-color: transparent;
      color: colors.$grey-900;
    }
  }
}
