@use "src/styles/variables";

.backBtn {
  width: 100%;
  padding: variables.px2rem(8px) 0 !important;
  margin-top: variables.px2rem(20px);
}

.greenBackButton {
  margin-top: variables.px2rem(10px);
  width: 100%;
}
