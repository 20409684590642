@use "src/styles/variables";
@use "src/styles/colors";
@use "src/styles/breakpoints";

.compareTiersBtnBox {
  display: flex;
  justify-content: center;

  .arrowFlip {
    transform: rotate(180deg);
  }
}

.comparisonTable {
  margin: 0 variables.px2rem(-20px);
  padding: variables.px2rem(40px) variables.px2rem(20px);
  z-index: 10;

  h3 {
    font-size: variables.px2rem(16px);
    font-weight: 700;
    line-height: variables.px2rem(22px);
    margin-bottom: variables.px2rem(10px);
  }

  :global(.ant-table-wrapper) {
    position: relative;
    left: 0;
    right: 0;
    background: #fff;
    padding: 0 variables.px2rem(20px);

    @include breakpoints.lg {
      position: static;
      left: auto;
      right: auto;
      padding: 0;
    }
  }

  :global(.ant-table-footer) {
    background: transparent;
  }

  :global(.ant-table-row) {
    td {
      padding: variables.px2rem(16px) !important;
      height: 55px !important;
      border-top: 1px solid #e4e4e4 !important;
      border-bottom: 1px solid #e4e4e4 !important;
      padding: variables.px2rem(10px) !important;

      svg {
        margin: 3px 0 0;

        @include breakpoints.lg {
          margin: 0;
        }
      }

      @include breakpoints.lg {
        border: none !important;
        padding: variables.px2rem(16px) !important;
        border-bottom: 1px solid #e4e4e4 !important;
      }

      &:first-child {
        border-left: 1px solid #e4e4e4 !important;
        border-top-left-radius: variables.px2rem(10px);
        border-bottom-left-radius: variables.px2rem(10px);

        @include breakpoints.lg {
          border-left: none !important;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &:nth-child(2) {
        border-left: 1px solid #e4e4e4 !important;
        border-right: 1px solid #e4e4e4 !important;

        @include breakpoints.lg {
          border-left: none !important;
          border-right: none !important;
        }
      }

      &:nth-child(3) {
        border-right: 1px solid #e4e4e4 !important;

        @include breakpoints.lg {
          border-right: none !important;
        }
      }

      &:last-child {
        border-right: 1px solid #e4e4e4 !important;
        border-top-right-radius: variables.px2rem(10px);
        border-bottom-right-radius: variables.px2rem(10px);

        @include breakpoints.lg {
          border-right: none !important;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  :global(.ant-table-expanded-row) {
    background-color: transparent;
    td {
      text-align: left !important;
      background: transparent !important;
      border-bottom: none !important;
    }
  }

  .tiersContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: variables.px2rem(10px);

    .table {
      width: 100%;

      th {
        background: #fff;
        border: none;

        font-weight: 500;
        font-size: variables.px2rem(20px);
        text-align: center;

        padding: 0;

        @include breakpoints.lg {
          padding: variables.px2rem(20px);
        }

        &:before {
          display: none;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        @include breakpoints.lg {
          &:first-child {
            text-align: left;
          }
        }
      }

      tr {
        td {
          text-align: center;

          @include breakpoints.lg {
            &:first-child {
              min-width: variables.px2rem(240px);
              text-align: left;
            }
          }
        }
      }

      .rowLight {
        td {
          background-color: #fff !important;
        }
      }

      .rowDark {
        @include breakpoints.lg {
          td {
            background-color: colors.$grey-100 !important;
          }
        }
      }

      .soonBadge {
        padding: variables.px2rem(5px) variables.px2rem(8px);
        border-radius: 100px;
        background: colors.$green-650;
        color: #fff;
      }
    }

    .tierBox {
      background-color: #fff;
      min-width: 250px;
      border: 1px solid variables.$button-stroke;
      border-radius: 12px;
      padding: variables.px2rem(23px) variables.px2rem(18px);

      & > span {
        margin-bottom: variables.px2rem(10px);
      }
    }
  }
}
