@use "src/styles/variables";
@use "src/styles/colors";

.guardianAlert {
  h2 {
    font-size: variables.px2rem(20px);
    line-height: variables.px2rem(24px);
    font-weight: 600;
    margin-bottom: variables.px2rem(5px);
  }

  .backToDashboard {
    border: 1px solid colors.$green-625;
    background-color: colors.$green-625;
    color: colors.$white;
    text-decoration: none;
    border-radius: 10px;
    height: 52px;
    margin-top: 20px;
    margin-bottom: 20px;

    &:hover {
      color: colors.$white;
    }
  }
}
