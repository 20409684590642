@use "src/styles/variables";

.uploadDocuments {
  h2 {
    font-size: variables.px2rem(20px);
    font-weight: 600;
    line-height: variables.px2rem(24px);
    margin-bottom: variables.px2rem(6px);
  }

  p {
    margin-bottom: variables.px2rem(20px);
  }

  .list {
    margin-top: variables.px2rem(40px);

    &Icon {
      width: 24px;
      height: 24px;
    }

    &Header {
      font-weight: 600;
    }
  }

  .sampleWrapper {
    margin-top: variables.px2rem(20px);
    margin-bottom: variables.px2rem(10px);
    cursor: pointer;

    p {
      color: variables.$text-color-secondary;
      font-size: variables.px2rem(10px);
      font-weight: 500;
      line-height: variables.px2rem(12px);
      text-transform: uppercase;
      margin-bottom: variables.px2rem(10px);
    }
  }
}
