@use "src/styles/variables";

.description {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: variables.$color-white;
  position: absolute;
  width: 100%;
  padding: 15px;
  left: 0px;
  top: 0px;
  background: linear-gradient(180deg, rgba(27, 27, 27, 0.5) 0%, rgba(27, 27, 27, 0.2) 100%);
  opacity: 0.9;
  // border-bottom: 1px solid variables.$text-color-main;
  z-index: 9;
}

.lightbox :global {
  .yarl__toolbar {
    left: 0;
    right: 0;
    margin: auto;
  }

  .yarl__button:disabled {
    opacity: 0.3;
  }

  .yarl__counter {
    bottom: 30px;
    color: rgba(255, 255, 255, 0.4);
    position: absolute;
    padding: 7px 60px;
    background: #323232;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 31px;

    &:first-letter {
      font-weight: 700;
      color: variables.$color-white;
    }
  }

  .yarl__navigation_prev {
    position: absolute;
    bottom: -25px;
    top: auto;
    left: auto;
    right: 51.2%;
    z-index: 9;
  }

  .yarl__navigation_next {
    position: absolute;
    left: 51.2%;
    right: auto;
    bottom: -25px;
    top: auto;
    z-index: 9;
  }
}
