@use "src/styles/variables";

.samples {
  display: flex;
  gap: variables.px2rem(15px);

  .imageContainer {
    width: variables.px2rem(85px);
    height: variables.px2rem(45px);
    border-radius: variables.px2rem(6px);
    transition: all 0.5s ease-in-out;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .overlay {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }

    &:hover {
      transform: scale(1.2);
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

      .overlay {
        opacity: 1;
        pointer-events: none;
      }
    }
  }
}
