@use "src/styles/variables";

.footer {
  margin: 0 auto;
  text-align: center;
  color: #fff;
  padding: 40px 0;

  span {
    display: inline-flex;
    align-items: center;
    gap: variables.px2rem(5px);
  }

  a {
    color: #fff;
    opacity: 0.5;
    display: inline-flex;
    align-items: center;
    gap: variables.px2rem(5px);

    &:hover {
      opacity: 0.6;
    }

    &.nonClickableText {
      cursor: default;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
