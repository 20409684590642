$grey-900: #323232;
$grey-800: #838383;
$grey-700: #757575;
$grey-600: #a2a2a2;
$grey-500: #b7b7b7;
$grey-300: #cacaca;
$grey-200: #e4e4e4;
$grey-150: #f6f6f6;
$grey-100: #f9f9f9;
$grey-50: #fcfcfc;

$green-900: #183118;
$green-700: #397237;
$green-675: #23b664;
$green-650: #2d9c5e;
$green-625: #2ec973;
$green-600: #52c44e;
$green-500: #58b255;
$green-400: #63a751;
$green-300: #85c783;
$green-100: #c2e3c1;
$green-50: #edfdec;

$orange-900: #ff6f00;
$orange-800: #816c12;
$orange-750: #cf9607;
$orange-700: #ffa000;
$orange-600: #faac2f;
$orange-500: #ffc107;
$orange-400: #e1b641;
$orange-300: #ffd54f;
$orange-200: #ffe082;
$orange-100: #ffecb3;
$orange-75: #fefae6;
$orange-50: #fef9e1;

$red-900: #320b09;
$red-700: #751915;
$red-600: #e02020;
$red-500: #b72720;
$red-400: #ff6161;
$red-300: #ca615c;
$red-100: #e4b0ad;
$red-50: #fee4e4;
$red-25: #fbeffe;

$purple-900: #24052b;
$purple-850: #471d57;
$purple-800: #651a76;
$purple-700: #530b64;
$purple-650: #321895;
$purple-600: #560e99;
$purple-550: #6a3a75;
$purple-500: #81109c;
$purple-400: #9238a6;
$purple-300: #a351b7;
$purple-250: #d027ff;
$purple-200: #e49df5;
$purple-175: #fbeaff;
$purple-150: #faecfd;
$purple-100: #f7f1f9;
$purple-50: #faecff;
$purple-25: #fefbff;

$white: #fff;
