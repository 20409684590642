@use "src/styles/variables";
@use "src/styles/colors";

.TierInfoModal {
  :global(.ant-modal-close) {
    width: variables.px2rem(36px);
    height: variables.px2rem(36px);

    svg {
      color: colors.$grey-900;
      width: variables.px2rem(15px);
      height: variables.px2rem(15px);
    }
  }

  :global(.ant-modal-content) {
    padding: variables.px2rem(40px) variables.px2rem(80px);
  }
}

.modalFooter {
  text-align: center;
  margin-top: variables.px2rem(40px);

  button {
    width: 100%;
    margin-bottom: variables.px2rem(15px);
  }

  a {
    text-decoration: underline;
    color: colors.$grey-900;
  }
}

.modalContent {
  color: colors.$grey-900;
  font-weight: 400;
  font-size: variables.px2rem(16px);
  line-height: variables.px2rem(24px);

  .modalTitle {
    font-weight: 600;
    font-size: variables.px2rem(20px);
    line-height: variables.px2rem(24px);
  }

  .modalGrid {
    padding: variables.px2rem(20px) 0;
    font-weight: 400;
    font-size: variables.px2rem(16px);
    line-height: variables.px2rem(24px);

    .modalGridTitle {
      font-weight: 600;
      font-size: variables.px2rem(16px);
    }

    .modalGridRow {
      padding: variables.px2rem(20px) 0;
      justify-content: space-between;

      &:not(:last-child) {
        border-bottom: 1px solid colors.$grey-200;
      }
    }

    .modalGridColumn {
      padding: 0 variables.px2rem(4px);
      font-size: variables.px2rem(14px);
      line-height: variables.px2rem(18px);
      flex: 1;

      .columnTitle {
        text-transform: uppercase;
        font-weight: 600;
        font-size: variables.px2rem(12px);
        line-height: variables.px2rem(14.4px);
      }
    }
  }
}
