@use "src/styles/variables";
@use "src/styles/breakpoints";

.contentBody {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: variables.px2rem(10px);
  padding: variables.px2rem(30px) variables.px2rem(20px);
  border-radius: variables.px2rem(20px);

  @include breakpoints.lg {
    border-radius: variables.px2rem(20px);
    padding: variables.px2rem(50px);
  }
}
