.documentsLanguage {
  h2 {
  }

  .answers {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 600;
  }
}
