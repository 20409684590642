@use "sass:math";
@use "./colors";

$font-family: Lato, Roboto, sans-serif;
:root {
  --main-font-family: $font-family;
}
$font-size-base: 14px;

@function px2rem($pxVal) {
  @return math.div($pxVal, $font-size-base) * 1rem;
}

$background-gradient: linear-gradient(180deg, #83109c 0%, #320b96 100%);

$text-color-main: colors.$grey-900;
$text-color-secondary: colors.$grey-600;

$button-success: colors.$green-625;
$button-success-hover: colors.$green-675;
$button-disabled: colors.$grey-150;
$button-stroke: colors.$grey-200;
$button-outline-background-hover: colors.$grey-100;
$button-outline-disabled: colors.$grey-300;

$alert-success: colors.$green-650;
$alert-warning: colors.$orange-900;
$alert-danger: colors.$red-600;

$alert-grey-out: colors.$grey-300;
$alert-grey-out-background: colors.$grey-100;

$tier-0: colors.$grey-300;
$tier-1: colors.$purple-500;
$tier-2: colors.$purple-800;
$tier-3: colors.$purple-650;

$color-primary: colors.$purple-500;

$color-white: colors.$white;
