@use "src/styles/variables";
@use "src/styles/breakpoints";
@use "src/styles/colors";

.tierBox {
  display: flex;
  flex-direction: column;
  gap: variables.px2rem(10px);
  width: 100%;
  margin: variables.px2rem(20px) 0;
  text-align: center;
  padding: variables.px2rem(20px);
  border: 1px solid variables.$button-stroke;
  border-radius: variables.px2rem(12px);

  .header {
    text-align: center;
    position: relative;

    .hnwLogo {
      position: absolute;
      left: 100%;
      transform: translateX(calc(-50% - 5px));
      top: 0;

      @include breakpoints.lg {
      }

      @include breakpoints.md {
      }
    }

    .subHeader {
      height: variables.px2rem(20px);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: variables.px2rem(4px);
      font-size: variables.px2rem(14px);
      color: colors.$grey-600;
    }
  }

  .tierIcon {
    text-align: center;
    margin: variables.px2rem(30px) 0;

    img {
      height: 79px;
    }
  }

  .tooltipPlaceholder {
    margin-top: variables.px2rem(-10px);
  }

  .infoSection {
    text-align: center;
    border-top: 1px solid colors.$grey-200;
    padding-top: variables.px2rem(18px);
    min-height: variables.px2rem(200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &.tier3Info {
      display: none;

      @include breakpoints.lg {
        display: flex;
      }
    }

    .infoSectionIcons {
      display: flex;
      gap: variables.px2rem(13px);
      justify-content: center;
      align-items: center;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    p {
      margin-top: 1em;
    }

    a {
      text-decoration: underline;
      color: colors.$grey-900;
    }
  }

  .bottomSection {
    border-top: 1px solid colors.$grey-200;
    padding-top: variables.px2rem(18px);

    .bottomSectionHeader {
      font-size: variables.px2rem(11px);
      text-transform: uppercase;
      font-weight: 600;

      text-align: left;

      @include breakpoints.lg {
        text-align: center;
      }
    }

    p {
      text-align: left;

      @include breakpoints.lg {
        text-align: center;
      }
    }

    .requirement {
      display: grid;
      grid-template-columns: 18px auto;
      align-items: center;
      gap: variables.px2rem(5px);
      text-align: left;

      @include breakpoints.lg {
        grid-template-columns: auto 1fr;
      }

      svg {
        stroke: colors.$grey-300;
        width: variables.px2rem(18px);
      }

      &:not(:last-child) {
        margin-bottom: variables.px2rem(8px);
      }
    }
  }

  > div {
    display: block;
  }

  &:first-of-type {
    margin-top: 0;
  }

  @include breakpoints.lg {
    text-align: left;
    margin: 0 0 variables.px2rem(20px);
  }

  h3 {
    margin-top: variables.px2rem(5px);
    margin-bottom: 0;
    font-size: variables.px2rem(16px);
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    span,
    svg {
      height: 16px;
    }
  }

  .statusBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: variables.px2rem(7px);
  }

  &.disabled {
    filter: grayscale(100%);
    cursor: not-allowed;

    [class^="TierBadge_"] {
      background-color: lightslategray;
    }

    button {
      pointer-events: none;
    }
  }

  .timeAlert {
    :global {
      .ant-alert-success {
        background-color: transparentize(colors.$green-625, 0.97);
        border-color: colors.$green-625;
      }
    }
  }
}

.statusBtn {
  width: 100%;
}
