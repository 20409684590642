@use "src/styles/variables";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: variables.px2rem(10px) variables.px2rem(15px);
  border-radius: variables.px2rem(8px);
  cursor: pointer;
  line-height: variables.px2rem(22px);
  color: #fff;
  font-weight: 600;
  gap: variables.px2rem(5px);

  border: 1px solid variables.$button-success;
  background-color: variables.$button-success;
  height: 38px;

  &:hover {
    background-color: variables.$button-success-hover;
  }

  &:disabled {
    background-color: variables.$button-disabled;
    border-color: variables.$button-disabled;
    color: variables.$text-color-secondary;
    cursor: not-allowed;
  }

  &.large {
    height: 52px;
    border-radius: variables.px2rem(10px);
    font-size: variables.px2rem(16px);
  }

  &.small {
    height: 30px;
  }

  &.outline {
    background-color: #fff;
    color: variables.$text-color-main;

    &:hover {
      background-color: variables.$button-outline-background-hover;
    }

    &:disabled {
      border-color: variables.$button-stroke;
      color: variables.$button-outline-disabled;
    }
  }

  &.outlineGrey {
    border-color: variables.$button-stroke;
    background-color: #fff;
    color: variables.$text-color-main;

    &:hover {
      background-color: variables.$button-outline-background-hover;
    }

    &:disabled {
      color: variables.$button-outline-disabled;
    }
  }

  &.link {
    padding: 0;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: variables.$text-color-main;
    height: unset;

    &:hover {
      color: variables.$button-success-hover;
    }

    &:disabled {
      color: variables.$button-outline-disabled;
    }
  }

  &.loading {
    border-color: variables.$button-stroke;
    background-color: #fff;
    cursor: default;

    .spinner {
      margin-top: variables.px2rem(6px);

      svg {
        stroke: variables.$button-success;
        stroke-width: 2;
      }
    }
  }
}
