@use "src/styles/variables";
@use "src/styles/breakpoints";
@use "src/styles/colors";

.accountTypePage {
  max-width: 1140px;
  margin: 0 auto;
  min-height: calc(100vh - 100px);

  .dividerIconPlacement {
    @include breakpoints.lg {
      top: 70px !important;
    }
  }

  .accountTypePageContent {
    margin-top: variables.px2rem(30px);
  }

  .tierDividerIcon {
    background-color: #fff;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dividerIcon {
      height: 16px;
      width: 16px;
      stroke: variables.$button-stroke;

      @include breakpoints.lg {
        transform: rotate(-90deg);
      }
    }
  }

  .certificationBox {
    padding: variables.px2rem(30px) variables.px2rem(20px);
    border: 1px solid variables.$button-stroke;
    border-radius: variables.px2rem(12px);

    
  }

  .boxHeader {
    margin-bottom: variables.px2rem(40px);

    h3 {
      font-size: variables.px2rem(20px);
      line-height: variables.px2rem(24px);
      margin-bottom: variables.px2rem(5px);
    }

    p {
      margin: 0;
      line-height: variables.px2rem(20px);
    }
  }

  .tierTable {
    display: grid;
    grid-template-columns: 1fr;
    gap: variables.px2rem(10px);

    @include breakpoints.lg {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    [class*="StatusBadge_pending__"] {
      p {
        font-size: variables.px2rem(12px);
      }
    }

    .certificationDivider {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: none;
      flex-grow: 0;
      max-height: 140px;
      width: 100%;
      text-transform: uppercase;
      font-size: variables.px2rem(10px);
      line-height: variables.px2rem(18px);

      span {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: variables.$button-disabled;
      }

      @include breakpoints.lg {
        width: 37px;
        line-height: variables.px2rem(10px);

        span {
          background-color: transparent;
        }
      }
    }

    .tierTableCol {
      flex: 1;
      flex-grow: 0;
    }
  }

  .isEddDisabled {
    pointer-events: none;
    color: colors.$grey-600;

    [class*="TierBadge_tierBadge__"] {
      background-color: colors.$grey-300;
    }

    [class*="StatusBadge_statusBadge__"] {
      color: colors.$grey-600;

      svg,
      path {
        fill: colors.$grey-600;
        stroke: colors.$grey-300;
      }
    }

    button {
      border: 1px solid colors.$grey-150;
      background-color: colors.$grey-150;
      color: colors.$grey-600;

      img {
        filter: grayscale(1);
        opacity: 0.5;
      }
    }
  }

  .pageHeader {
    display: flex;
    justify-content: space-between;

    @include breakpoints.lg {
      margin-bottom: variables.px2rem(50px);
    }

    .pageHeaderAccountType {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .backBtn {
      margin-top: 0;
      margin-right: variables.px2rem(20px);
      text-decoration: none;
      color: inherit;
      display: flex;
      align-items: center;
      font-size: variables.px2rem(20px);
      gap: variables.px2rem(10px);
  
      &:hover {
        text-decoration: underline;
      }
      svg {
        height: variables.px2rem(20px);
        width: variables.px2rem(20px);
        transform: rotate(90deg);
      }
    }
  }

  .accountTypeLabel {
    gap: variables.px2rem(6px);
  }

  
}
