@use "src/styles/variables";

.summary {
  margin-bottom: variables.px2rem(50px);

  &Header {
    display: flex;
    align-items: center;
    margin-bottom: variables.px2rem(30px);
    gap: variables.px2rem(20px);

    h3 {
      font-weight: 600;
      font-size: variables.px2rem(16px);
      line-height: variables.px2rem(22px);
      margin-bottom: 0;
    }
  }

  &Data {
    display: flex;
  }

  &Item {
    &Header {
      font-weight: 600;
      font-size: variables.px2rem(12px);
      line-height: variables.px2rem(14px);
      text-transform: uppercase;
      margin-bottom: variables.px2rem(5px);
    }

    &Value {
      color: variables.$text-color-secondary;
      text-transform: capitalize;
      font-size: variables.px2rem(16px);

      .documentsList {
        display: flex;
        flex-direction: column;
        gap: variables.px2rem(10px);
        margin-top: variables.px2rem(10px);

        .document {
          display: flex;
          align-items: center;
          gap: variables.px2rem(8px);
          text-transform: none;

          svg {
            width: 24px;
            height: 24px;
          }

          span {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          color: variables.$text-color-main;
          font-size: variables.px2rem(16px);
          line-height: variables.px2rem(22px);
        }
      }
    }
  }

  .divider {
    margin: 0;
  }
}
